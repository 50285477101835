<template>
  <b-card>
    <g-form @submit="save">
      <!-- Father Section -->
      <div class="header">
        <feather-icon
          icon="UserIcon"
          stroke="white"
          class="primary"
          size="35"
        />
        <h4 class="mt-50 ml-1">
          {{ $t("fatherInformation") }}
        </h4>
      </div>

      <b-row :class="{ 'hide-code': id == undefined }">
        <!-- code  -->
        <b-col md="12">
          <b-form-group>
            <g-field
              id="code"
              type="number"
              name="code"
              label-text="code"
              readonly
              :value.sync="selectedItem.code"
              disabled
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <!-- father arabic name -->
        <b-col md="4">
          <b-form-group>
            <g-field
              id="arabicName"
              ref="arabicName"
              name="arabicName"
              label-text="fatherName"
              rules="required"
              :value.sync="selectedItem.arabicName"
            />
          </b-form-group>
        </b-col>
        <!-- father english name -->
        <b-col md="4">
          <b-form-group>
            <g-field
              id="englishName"
              ref="englishName"
              name="englishName"
              label-text="fatherEnglishName"
              :value.sync="selectedItem.englishName"
            />
          </b-form-group>
        </b-col>
        <!-- father identity Number  -->
        <b-col md="4">
          <b-form-group>
            <g-field
              id="fatherIdentityNo"
              ref="fatherIdentityNo"
              name="fatherIdentityNo"
              label-text="identityNo"
              rules="required|integer"
              :value.sync="selectedItem.fatherIdentityNo"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <!-- father identity type  -->
        <b-col md="4">
          <b-form-group>
            <g-field
              field="select"
              label="arabicName"
              label-text="identityType"
              name="IdentityType"
              :value.sync="selectedItem.fatherIdentityType"
              :dir="isRight ? 'rtl' : 'ltr'"
              :options="identityTypes"
            />
          </b-form-group>
        </b-col>
        <!-- father identity source -->
        <b-col md="4">
          <b-form-group>
            <g-field
              id="fatherIdentitySource"
              ref="fatherIdentitySource"
              name="fatherIdentitySource"
              label-text="identitySource"
              :value.sync="selectedItem.fatherIdentitySource"
            />
          </b-form-group>
        </b-col>
        <!-- father mail  -->
        <b-col md="4">
          <b-form-group>
            <g-field
              id="fatherMail"
              ref="fatherMail"
              name="fatherMail"
              label-text="email"
              rules="email"
              :value.sync="selectedItem.fatherMail"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <!-- father education qualification  -->
        <b-col md="4">
          <b-form-group>
            <g-field
              field="select"
              label="arabicName"
              label-text="educationQualification"
              name="educationQualification"
              :value.sync="selectedItem.fatherEducationQualification"
              :dir="isRight ? 'rtl' : 'ltr'"
              :options="educationQualifications"
            />
          </b-form-group>
        </b-col>
        <!-- nationality -->
        <b-col md="4">
          <b-form-group>
            <g-field
              field="select"
              label="arabicName"
              label-text="nationality"
              name="nationality"
              rules="required"
              :value.sync="selectedItem.nationalityId"
              :dir="isRight ? 'rtl' : 'ltr'"
              :options="nationalities"
            />
          </b-form-group>
        </b-col>

        <!-- mobile number  -->
        <b-col md="4">
          <b-form-group>
            <label class="col-form-label pt-0">{{ $t("contactNumber") }}</label>
            <b-form-input v-model="selectedItem.mobileNumber" type="number" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <!-- mobile number 2  -->
        <b-col md="4">
          <b-form-group>
            <label class="col-form-label pt-0">{{ $t("mobileNumber2") }}</label>
            <b-form-input
              v-model="selectedItem.fatherMobileNumber2"
              type="number"
            />
          </b-form-group>
        </b-col>
        <!-- landline no -->
        <b-col md="4">
          <b-form-group>
            <label class="col-form-label pt-0">{{
              $t("landlineNumber")
            }}</label>
            <b-form-input
              v-model="selectedItem.fatherLandlineNo"
              type="number"
            />
          </b-form-group>
        </b-col>

        <!-- arrival station  -->
        <b-col md="4">
          <b-form-group>
            <g-field
              id="FatherArrivalStation"
              ref="FatherArrivalStation"
              name="FatherArrivalStation"
              label-text="arrivalStation"
              :value.sync="selectedItem.fatherArrivalStation"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <!-- job -->
        <b-col md="4">
          <b-form-group>
            <g-field
              id="fatherJob"
              ref="fatherJob"
              name="fatherJob"
              label-text="fatherJob"
              :value.sync="selectedItem.fatherJob"
            />
          </b-form-group>
        </b-col>
        <!-- father work phone number  -->
        <b-col md="4">
          <b-form-group>
            <label class="col-form-label pt-0">{{ $t("workPhone") }}</label>
            <b-form-input
              v-model="selectedItem.fatherWorkPhoneNo"
              type="number"
            />
          </b-form-group>
        </b-col>
        <!-- address -->
        <b-col md="4">
          <b-form-group>
            <g-field
              id="address"
              ref="address"
              name="address"
              label-text="address"
              :value.sync="selectedItem.address"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <!-- district  -->
        <b-col md="4">
          <b-form-group>
            <g-field
              id="district"
              ref="district"
              name="district"
              label-text="district"
              :value.sync="selectedItem.district"
            />
          </b-form-group>
        </b-col>
        <!-- street  -->
        <b-col md="4">
          <b-form-group>
            <g-field
              id="street"
              ref="street"
              name="street"
              label-text="street"
              :value.sync="selectedItem.street"
            />
          </b-form-group>
        </b-col>
        <!-- building  -->
        <b-col md="4">
          <b-form-group>
            <g-field
              id="building"
              ref="building"
              name="building"
              label-text="building"
              :value.sync="selectedItem.building"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Mother Section -->
      <div class="header">
        <feather-icon
          icon="UserIcon"
          stroke="white"
          class="primary"
          size="35"
        />
        <h4 class="mt-50 ml-1">
          {{ $t("motherInformation") }}
        </h4>
      </div>

      <b-row>
        <!-- mother name  -->
        <b-col md="4">
          <b-form-group>
            <g-field
              id="motherName"
              ref="motherName"
              name="motherName"
              label-text="motherName"
              :value.sync="selectedItem.motherName"
            />
          </b-form-group>
        </b-col>
        <!-- mother education qualification  -->
        <b-col md="4">
          <b-form-group>
            <g-field
              field="select"
              label="arabicName"
              label-text="educationQualification"
              name="motherEducationQualification"
              :value.sync="selectedItem.motherEducationQualification"
              :dir="isRight ? 'rtl' : 'ltr'"
              :options="educationQualifications"
            />
          </b-form-group>
        </b-col>
        <!-- mother work location -->
        <b-col md="4">
          <b-form-group>
            <g-field
              id="motherWorkLocation"
              ref="motherWorkLocation"
              name="motherWorkLocation"
              label-text="motherWorkLocation"
              :value.sync="selectedItem.motherWorkLocation"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <!-- mother phone number -->
        <b-col md="4">
          <b-form-group>
            <label class="col-form-label pt-0">{{ $t("contactNumber") }}</label>
            <b-form-input v-model="selectedItem.motherPhoneNo" type="number" />
          </b-form-group>
        </b-col>
        <!-- mother work phone no -->
        <b-col md="4">
          <b-form-group>
            <label class="col-form-label pt-0">{{ $t("workPhone") }}</label>
            <b-form-input
              v-model="selectedItem.motherWorkPhoneNo"
              type="number"
            />
          </b-form-group>
        </b-col>
        <!-- mother mail -->
        <b-col md="4">
          <b-form-group>
            <g-field
              id="motherMail"
              ref="motherMail"
              name="motherMail"
              label-text="email"
              rules="email"
              :value.sync="selectedItem.motherMail"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <hr />
        </b-col>
      </b-row>

      <!-- General information Section -->
      <div class="header">
        <feather-icon
          icon="GiftIcon"
          stroke="white"
          class="primary"
          size="35"
        />
        <h4 class="mt-50 ml-1">
          {{ $t("generalInformation") }}
        </h4>
      </div>

      <b-row>
        <!-- how did you know us -->
        <b-col md="4">
          <b-form-group>
            <g-field
              field="select"
              label="arabicName"
              label-text="schoolRecognitionMethod"
              name="schoolRecognitionMethod"
              :value.sync="selectedItem.schoolRecognitionMethod"
              :dir="isRight ? 'rtl' : 'ltr'"
              :options="schoolRecognitionMethods"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Responsible Section -->
      <div class="header">
        <feather-icon
          icon="InfoIcon"
          stroke="white"
          class="primary"
          size="35"
        />
        <h4 class="mt-50 ml-1">
          {{ $t("responsibleInformation") }}
        </h4>
      </div>

      <b-row>
        <!-- responsible name -->
        <b-col md="4">
          <b-form-group>
            <g-field
              id="responsibleName"
              ref="responsibleName"
              name="responsibleName"
              label-text="responsibleName"
              :value.sync="selectedItem.responsibleName"
            />
          </b-form-group>
        </b-col>

        <!-- responsible identity number -->
        <b-col md="4">
          <b-form-group>
            <g-field
              id="responsibleIdentityNo"
              ref="responsibleIdentityNo"
              name="responsibleIdentityNo"
              label-text="identityNo"
              rules="integer"
              :value.sync="selectedItem.responsibleIdentityNo"
            />
          </b-form-group>
        </b-col>

        <!-- responsible phone number -->
        <b-col md="4">
          <b-form-group>
            <label class="col-form-label pt-0">{{
              $t("responsiblePhoneNo")
            }}</label>
            <b-form-input
              v-model="selectedItem.responsiblePhoneNo"
              type="number"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <!-- is school employee -->
        <b-col md="4">
          <b-form-group :label="$t('isSchoolEmployee')">
            <b-form-checkbox
              v-model="selectedItem.isSchoolEmployee"
              class="custom-control-primary"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <!-- notes -->
        <b-col md="12">
          <b-form-group>
            <label class="mb-1" style="font-size: 14px">
              {{ $t("notes") }}
            </label>
            <b-form-textarea
              id="textarea"
              v-model="selectedItem.notes"
              label="notes"
              rows="3"
              max-rows="6"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Operations Section -->
      <b-row>
        <b-col cols="12" class="d-flex justify-content-end mb-1">
          <b-button
            v-permission="$route.meta.permission"
            class="mx-1"
            type="submit"
            data-action-type="save"
            variant="primary"
          >
            {{ $t("save") }}
          </b-button>
        </b-col>
      </b-row>
    </g-form>
  </b-card>
</template>

<script>
import {
  identityTypes, educationQualifications, schoolRecognitionMethods
} from '@/libs/acl/Lookups';

export default {
  props: ['id'],

  data() {
    return {
      nationalities: [],
      identityTypes: identityTypes,
      educationQualifications: educationQualifications,
      schoolRecognitionMethods: schoolRecognitionMethods,
      selectedItem: {
        englishName: ''
      }
    };
  },

  mounted() {
    this.getNationalities();
    if (this.id > 0) {
      this.getSelectedGuardian();
    }
  },

  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    getNationalities() {
      this.get({ url: 'nationalities' }).then((data) => {
        this.nationalities = data;
      });
    },

    getSelectedGuardian() {
      this.get({ url: 'Guardians', id: this.id }).then((data) => {
        this.selectedItem = data;
      });
    },

    save() {
      this.selectedItem.branchId = this.branchId;
      if (this.selectedItem.id > 0) {
        this.update({
          url: 'Guardians',
          data: this.selectedItem,
          id: this.selectedItem.id,
        }).then(() => {
          this.doneAlert({ text: this.$t('updatedSuccessfully') });
          this.$router.push({ name: 'guardians-list' });
        });
      } else {
        this.create({
          url: 'Guardians',
          data: this.selectedItem
        }).then(() => {
          this.doneAlert({ text: this.$t('savedSuccessfully') });
          this.$router.push({ name: 'guardians-list' });
        });
      }
    }
  },
};
</script>

<style>
.header {
  display: flex;
  margin-bottom: 1rem;
  padding: 0.5rem;
  background-color: #a074c7;
  border-radius: 5px;
}
.hide-code {
  display: none;
}

.header h4 {
  color: white;
}
</style>
